@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@300;400;500;700&display=swap');

body {
  margin: 0;
  font-family: 'Tajawal', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  direction: rtl;
}

code {
  font-family: 'Tajawal', sans-serif;
}

* {
  font-family: 'Tajawal', sans-serif;
  text-transform: capitalize;
}

:root {
  --color: #09b6ac;
  --color2: #fcaf17;
}

/* start navbar */
.head,
.head2 {
  background-image: url('https://www.careem.com/pubweb-v2/ride/images/ride-bg-ar.jpg');
  height: 570px;
  background-size: cover;
  position: relative;
}

.head2 {
  background-image: url('https://www.careem.com/pubweb-v2/about-us/images/about-bg-ar.jpg');
}

.safety .head2 {
  background-image: url('https://www.careem.com/pubweb-v2/careers/images/careers-bg.jpg');
}

.navbar img {
  width: 130px;
}

.navbar i {
  font-size: 24px;
  color: #fff;
  margin-left: 5px;
  cursor: pointer;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.textHead {
  position: absolute;
  top: 50%;
  width: 32%;
}

.head2 .textHead h5 {
  color: #fff;
  font-size: 24px;
  line-height: 44px;
}

@media(max-width:1465px) {
  .textHead {
    width: 40%;
  }
}

@media(max-width:1100px) {
  .textHead {
    width: 50%;
  }
}

@media(max-width:800px) {
  .textHead {
    width: 90%;
    text-align: center;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
  }
}

.textHead h1 {
  color: #fff;
  line-height: 1.2;
  font-size: 55px;
}

.navbar {
  background-color: var(--color);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  position: fixed;
}

.navbar button {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  padding: 7px 30px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

/* end navbar */
/* start sidbar */
.sidbar {
  background: var(--color);
  width: 275px;
  position: fixed;
  top: 0;
  right: -100%;
  z-index: 9;
  height: 100vh;
  transition: 0.6s;
  -webkit-transition: 0.6s;
  -moz-transition: 0.6s;
  -ms-transition: 0.6s;
  -o-transition: 0.6s;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.15), 0 15px 12px rgba(0, 0, 0, 0.10);
}

.sidbar .close {
  display: flex;
  justify-content: space-between;
  padding: 30px;
  align-items: center;
}

.sidbar .close img {
  width: 120px;
}

.sidbar .close i {
  color: #fff;
  font-size: 25px;
  cursor: pointer;
}

.contLink a {
  text-decoration: none;
  color: #fff;
  display: block;
  padding: 7px 30px;
  font-size: 18px;
}

.contLink a:hover {
  color: #fff;
}

/* end sidbar */
/* start advantages */
.advantages {
  margin: 20px 0;
  padding: 60px 0;
  text-align: center;
}

.advantages img {
  width: 60px;
  margin-bottom: 30px;
}

.advantages h4 {
  margin-bottom: 0.75rem;
  font-size: 18px;
  line-height: 1.22;
  color: var(--color2);
  font-weight: 600;
}

.advantages p {
  font-size: 14px;
  color: #606c74;
  padding: 0 15px;
  font-weight: 500;
}

/* start advantages */
/* start steps */
.steps {
  padding-top: 80px;
  padding-bottom: 80px;
  min-height: 80px;
  background-color: #f9fafb;
  text-align: center;
}

.steps h3 {
  margin-bottom: 40px;
  color: var(--color2);
}

.stepCont {
  padding-top: 50px;
}

.stepCont .stepimg {
  text-align: left;
}

.eg .stepCont .stepimg {
  text-align: right;
}

.stepText {
  text-align: right;
  margin-bottom: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.eg .stepText {
  text-align: left;
}

.eg .step-body {
  margin-left: 20px;
}

.stepText .step-counter {
  float: left;
  width: 82px;
}

.stepText .step-counter span {
  width: 82px;
  height: 82px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 14px solid #f6f8f9;
  color: var(--color);
  transition: all .2s ease-in-out;
}

.stepText.active .step-counter span {
  background-color: var(--color2);
  border-color: #EEF0F1;
  color: #fff;
}

.step-body {
  margin-right: 20px;
}

.stepText .step-title {
  font-size: 18px;
  line-height: 1.38;
  color: var(--color2);
  /* font-weight: 600; */
}

.stepText .step-description {
  font-size: 14px;
  line-height: 1.29;
  font-weight: 500;
  color: #606c74;
  margin-bottom: 0;
}

.stepActive {
  margin-top: 30px;
  padding-right: 38px;
}

.eg .stepActive {
  padding-right: 0;
  padding-left: 38px;
}

@media(max-width:800px) {
  .stepCont .stepimg .stepText .step-counter span {
    width: 60px;
    height: 60px;
  }

  .stepText .step-title {
    font-size: 15px;
  }

  .stepText .step-description {
    font-size: 13px;
  }
}

@media(max-width:580px) {

  .stepCont .stepimg,
  .eg .stepCont .stepimg {
    text-align: center;
  }

}

/* end steps */

/* start category */
.category {
  padding-top: 80px;
  text-align: center;
}

.category h3 {
  font-size: 26px;
  margin-bottom: 65px;
  color: var(--color2);
}

.category .nav-tabs {
  justify-content: center;
  border-bottom: 0 !important;
  padding-right: 0;
}

.tabsCont img {
  max-width: 120px;
  margin-bottom: 15px;
}

.tabsCont h5 {
  font-size: 15px;
  color: var(--color2);
}

.category .nav-item {
  width: 130px;
  margin: 0 10px;
  width: 235px;
}

.category .nav-link {
  border: 0 !important;
  padding: 25px 0;
  width: 100%;
}

.category .nav-link.active {
  background-color: #f9fafb !important;
  border-top: 1px solid var(--color) !important;
  outline: 0;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
}

.category .tab-content {
  background-color: #f9fafb !important;
  padding: 80px 0;
}

.category .tab-content .contcategoryimg {
  text-align: left;
}

.eg .category .tab-content .contcategoryimg {
  text-align: right;
}

.category .tab-content .contcategorytext {
  text-align: right;
}

.eg .category .tab-content .contcategorytext {
  text-align: left;
}

.category .tab-content .contcategorytext h5 {
  font-size: 26px;
  color: var(--color2);
}

.category .tab-content .contcategorytext p {
  font-size: 16px;
  color: #606c74;
  font-weight: 500;
  width: 71%;
}

.category .tab-content img {
  max-width: 100%;
}

/* end category */

/* start packages */
.packages {
  background-image: url("https://www.careem.com/pubweb-v2/ride/images/packages-ar.jpg");
  height: 400px;
  position: relative;
  background-size: cover;
}

.packages .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000057;
}

.packText {
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translatey(-50%);
}

.packText h5 {
  font-size: 28px;
  color: #ffffff;
  margin-bottom: 20px;
  font-weight: 500;
}

.packText p {
  font-size: 15px;
  color: #ffffff;
  margin-bottom: 20px;
  font-weight: 500;
}

.packText button {
  padding: 8px 12px;
  font-size: 14px;
  background-color: var(--color);
  border: 0 solid var(--color);
  outline: none;
  color: #fff;
  font-weight: 500;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -ms-transition: 0.4s;
  -o-transition: 0.4s;
}

.packText button:hover {
  background-color: #056d61;
}

/* end packages */

/* start explore */
.explore {
  padding: 80px 0;
  text-align: center;
}

.explore>h5 {
  margin-bottom: 60px;
  font-size: 28px;
  font-weight: 500;
  color: var(--color2);
}

.explore .careem-story-card.compact {
  height: 217px;
  margin-bottom: 20px;
}

.explore .careem-story-card.compact .careem-story-card-body {
  height: 100%;
}

.explore .careem-story-card.compact .careem-story-card-body {
  background-color: #f9fafb;
  padding: 30px 30px 50px 30px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.explore .careem-story-card.compact .careem-story-card-body h5 {
  margin-bottom: 0.75rem;
  font-size: 18px;
  line-height: 1.22;
  color: var(--color2);
  font-weight: 600;
}

.explore .careem-story-card.compact .careem-story-card-body p {
  font-size: 14px;
  color: #606c74;
  font-weight: 500;
}

.explore .careem-story-card.compact .careem-story-card-body a {
  font-size: 16px;
  color: var(--color);
  font-weight: 500;
  text-decoration: none;
}

/* end explore */
/* start footer */
.footerTop {
  background-color: var(--color);
  height: 200px;
}

.footerTop .footerTopCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 200px;
}

.footerTop img {
  width: 130px;
}

.footerTop .footerTopCont .footerMenue ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.footerTop .footerTopCont .footerMenue ul li a {
  text-decoration: none;
  color: #fff;
  position: relative;
  margin-right: 20px;
}

.eg .footerTop .footerTopCont .footerMenue ul li a {
  /* margin-right: 0; */
  margin-left: 20px;
}

.footerTop .footerTopCont .footerMenue ul li:first-of-type {
  margin-left: 20px;
}

.footerTop .footerTopCont .footerMenue ul li:last-of-type::before {
  position: absolute;
  content: '';
  width: 1px;
  height: 25px;
  background-color: #fff;
}

.footerBott {
  background-color: #2e2d2e;
  height: 100px;
}

.footerBott .footerBottText p {
  color: #fff;
  margin-bottom: 0;
}

.footerBottCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
}

.footerBootIcon ul {
  display: flex;
  padding: 0;
  list-style: none;
}

.footerBootIcon ul li {
  margin-right: 30px;
  background-color: #1877F2;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.footerBootIcon ul li:nth-of-type(2) {
  background-color: #1DA1F2;
}

.footerBootIcon ul li:nth-of-type(3) {
  background-color: #0A66C2;
}

.footerBootIcon ul li a {
  color: #fff;
}

@media(max-width:470px) {
  .footerTop .footerTopCont img {
    width: 70px;
  }

  .footerTop .footerTopCont .footerMenue ul li a {
    font-size: 13px;
  }

  .footerTop .footerTopCont .footerMenue ul li:last-of-type::before {
    height: 15px;
    margin-top: 5px;
  }

  .footerBott .footerBottText p {
    font-size: 12px;
  }

  .footerBootIcon ul li {
    width: 25px;
    height: 25px;
    line-height: 30px;
  }
}

@media(max-width:360px) {
  .footerTop .footerTopCont .footerMenue ul li a {
    font-size: 12px;
  }
}

/* end footer */

/* start TermsOfService and PrivacyPolicy */
.TermsOfService,
.PrivacyPolicy {
  padding: 50px 0;
}

.TermsOfService h3,
.PrivacyPolicy h3 {
  margin-bottom: 50px;
}

.termsCont {
  box-shadow: 1px 1px 8px -6px #1a6bb4;
  padding: 20px;
}

.termsCont h5 {
  margin-bottom: 15px;
}

/* end TermsOfService and PrivacyPolicy */
/* start about */
.subAbout {
  margin: 30px 0;
  overflow: hidden;
}

.aboutCont {
  margin: 50px 0;
}

.aboutCont h4,
.aboutCont2 h4,
.aboutCont1 h4 {
  color: var(--color2);
}

.aboutCont p,
.aboutCont1 p,
.aboutCont2 p,
.aboutImgText p {
  color: #606c74;
}

.aboutImg img {
  width: 100%;
}

.aboutImgText h4 {
  color: var(--color2);
}

.subAbout2Child {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 20px 5px rgb(0 0 0 / 5%);
}

.subAbout2Child h4 {
  color: var(--color2);
}

.subAbout2Child p {
  color: #606c74;
}

.subAbout2 {
  margin: 60px 0;
}

.subAbout2Child img {
  width: 40%;
}

.subAbout2Child {
  margin: 10px 0;
}

.subAbout2Child div {
  padding: 20px;
  width: 60%;
}

@media(max-width:550px) {
  .subAbout2Child {
    display: block;
  }

  .subAbout2Child img {
    margin: 0px;
    width: 100%;
  }


}

/* end about */
/* start safety */
.safety .aboutCont p {
  text-align: center;
  padding: 0px 20%;
  color: var(--color2) !important;
}

.client {
  text-align: center;
}

.subsafety {
  text-align: center;
}

.joinTeam .subsafety {
  margin: 100px 0;
}

.subsafety h4 {
  margin: 40px 0;
  color: var(--color2);
}

.subsafety .subsefetyChild {
  height: 550px;
}

.subsafety .subsefetyChild img {
  width: 100%;
}

.subsefetyCont {
  padding: 20px 0;
}

.subsefetyCont h5 {
  margin-bottom: 0.75rem;
  font-size: 18px;
  line-height: 1.22;
  color: var(--color2);
  font-weight: 500;
}

.subsefetyCont p {
  color: #606c74;
  font-weight: 400;
}

@media(max-width:770px) {
  .subsafety .subsefetyChild {
    height: auto;
  }
}

@media(max-width:650px) {
  .safety .aboutCont p {
    padding: 0;
  }
}

/* end safety */
.aboutImg1 {
  animation: fadeInRight;
  animation-duration: 2s;
}

.aboutCont1 {
  animation: fadeInDown;
  animation-duration: 2s;
}

.aboutImg2 {
  animation: fadeInLeft;
  animation-duration: 2s;
}

.aboutCont2 {
  animation: fadeInUp;
  animation-duration: 2s;
}